import * as React from "react"

import companyDatas from '../data/company'
import "../assets/scss/404.css"

import nofound from '../assets/img/404.webp'

const NotFoundPage = () => {
  return (
    <main>
      <div class="fullheight container">
        <div class="page_404" style={{backgroundImage: `url(${nofound})`}}>
          <div class="wrap">
            <div class="wrap_float">
              <a href="/" class="logo" style={{display: 'flex', alignItems: 'center'}}>
                <img src={companyDatas.icon} alt="cotraveling" style={{height: 34, marginRight: 10}} />
                <span>{companyDatas.name}</span>
              </a>
              <div class="center-text">
                <div class="title">404 page non trouvée</div>
                <div class="subtitle">Désolé, nous n'avons pas trouvé la page que vous recherchez.</div>
                <a href="/" class="btn"><span>Retour à l'accueil</span></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default NotFoundPage
